import style from './mobile-menu.module.sass'
import { Link } from "gatsby"
import React from 'react';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }
  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }
  render() {
    let menuToggleState = this.state.showMenu ? style.toggleClose : style.toggleOpen;
    let toggleClassNames = menuToggleState + ' ' + style.containerMenuMobile
    let hamburgerState = this.state.showMenu ? style.hamburgerOpen : style.hamburgerClose
    let hamburgerClass = hamburgerState + ' ' + style.hamburgerLine
    return (
      <div>
        <button className={style.button} onClick={this.toggleMenu}>
          <div className={style.hamburgerLineContainer}>
            <div className={hamburgerClass}></div>
            <div className={hamburgerClass}></div>
            <div className={hamburgerClass}></div>
            <div className={hamburgerClass}></div>
          </div>
        </button>
        <div className={toggleClassNames}>
          <div className={style.menu}>
            <ul>
              <li>
                <Link onClick={this.toggleMenu} to="/" className={style.menuLink}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/music/" className={style.menuLink}>
                  Music
                </Link>
              </li>
              {/* <li>
                <Link to="/blog/" className={style.menuLink}>
                  Blog
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div >
    )
  }
}

export default MobileMenu