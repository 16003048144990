import React from 'react';
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import MusicPlayerSmall from '../music-player-small/music-player-sm.jsx'
import DesktopMenu from "../menu/desktop-menu.jsx"
import MobileMenu from "../menu/mobile-menu.jsx"
import SomeIcons from "../some-icons/some-icons.jsx"
import NewsletterForm from "../newsletter-form/form.jsx"
import layoutStyles from "./layout.module.sass"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <h3 className={layoutStyles.topLogo}>SA ZEINER</h3>
          <DesktopMenu />
          <MobileMenu />
          <MusicPlayerSmall />
          <main className={layoutStyles.main}>{children}</main>
          <footer>
            <SomeIcons />
          </footer>
          <NewsletterForm />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
