import { Link } from "gatsby"
import React from "react"
import style from "./desktop-menu.module.sass"

const DesktopMenu = () => (
  <div className={style.containerMenuDesktop}>
    <div className={style.navbar}>
      <ul>
        <li>
          <Link to="/" activeClassName={style.activeLink} className={style.menuLink}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/music/" activeClassName={style.activeLink} className={style.menuLink}>
            Music
          </Link>
        </li>
        {/* <li>
          <Link to="/blog/" activeClassName={style.activeLink} className={style.menuLink}>
            Blog
          </Link>
        </li> */}
      </ul>
    </div>
  </div>
)

export default DesktopMenu
