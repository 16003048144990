import React from 'react'
import style from './some-icons.module.sass'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const SomeIcons = () => (
  <div className={style.container}>
    <div className={style.iconGroup}>
      <a href="https://www.facebook.com/sazeiner" className={[style.icon]}>
        <FaFacebookF className={style.fa} />
      </a>
      <a href="https://www.twitter.com/sazeiner" className={[style.icon]}>
        <FaTwitter className={style.fa} />
      </a>
      <a href="https://www.instagram.com/sazeiner" className={[style.icon]}>
        <FaInstagram className={style.fa} />
      </a>
    </div>
  </div >
)

export default SomeIcons;