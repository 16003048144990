import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import style from "./music-player-sm.module.sass"
import Prev from '../../images/media-player/prev.svg';
import Play from '../../images/media-player/play.svg';
import Pause from '../../images/media-player/pause.svg';
import Next from '../../images/media-player/next.svg';

export default () => {
  let contentFullSong = useStaticQuery(query).allContentfulSong.edges

  const songs = []
  contentFullSong.map((song) => {
    return songs.push({
      title: song.node.title,
      file: song.node.song.file.url
    })
  })

  const [currentSongNumber, setCurrentSongNumber] = useState()
  const [currentSongTitle, setCurrentSongTitle] = useState()
  const [playPauseIcon, setPlayPauseIcon] = useState(<Play />)
  let audio;

  if (typeof window !== 'undefined') {
    [audio] = useState(new Audio());
  }

  useEffect(
    () => {
      if (currentSongNumber !== undefined) {
        audio.src = songs[currentSongNumber].file
        setCurrentSongTitle(songs[currentSongNumber].title)
        audio.play()
        setPlayPauseIcon(<Pause />)
      }
      return () => {
        audio.pause()
      }
    },
    [currentSongNumber]
  );

  const playSong = () => {
    if (currentSongNumber === undefined) {
      setCurrentSongNumber(0)
      setCurrentSongTitle(songs[0].title)
      audio.src = songs[0].file
      audio.play()
    } else {
      if (audio.paused) {
        audio.play()
        setPlayPauseIcon(<Pause />)
        setCurrentSongTitle(songs[currentSongNumber].title)
      } else {
        audio.pause();
        setPlayPauseIcon(<Play />)
        setCurrentSongTitle('')
      }
    }
  }

  const nextSong = () => {
    if (currentSongNumber < contentFullSong.length - 1) {
      setCurrentSongNumber(currentSongNumber + 1)
    } else {
      setCurrentSongNumber(0)
    }
  }

  const prevSong = () => {
    if (currentSongNumber > 0) {
      setCurrentSongNumber(currentSongNumber - 1)
    } else {
      setCurrentSongNumber(contentFullSong.length - 1)
    }
  }

  return (
    <div className={style.playerContainer}>
      <div className={style.player}>
        <div onClick={prevSong} className={style.prev}><Prev /></div>
        <div onClick={playSong} className={style.play}>{playPauseIcon}</div>
        <div onClick={nextSong} className={style.next}><Next /></div>
      </div>
      <div>
        <div className={style.songTitle}>{currentSongTitle}</div>
      </div>
    </div>
  )
}

export const query = graphql`
query {
  allContentfulSong {
    edges {
      node {
        buyLink
        downloadLink
        song {
          file {
            url
          }
        }
        streamLink
        title
      }
    }
  }
}
`
