import React, { useState, useEffect } from 'react'
import style from './form.module.sass'
import { setCookie, getCookie } from '../../utilities/cookies'
import SubmitButton from '../submit-button/submit-button'
import trackEvent from '../../utilities/trackEvent';

const NewsletterForm = () => {

    const modalCookie = getCookie("closedNewsletterModal")

    const [isClosed, setIsClosed] = useState(style.closedModal)
    const [responseMessage, setResponseMessage] = useState()
    const [buttonState, setButtonState] = useState()

    useEffect(() => {
        clearTimeout(window.modalTimer);
        if (modalCookie === null) {
            window.modalTimer = setTimeout(() => {
                setIsClosed(style.openModal)
            }, 20000)
        }
        },[modalCookie])

    const toggleModal = () => {
        setCookie("closedNewsletterModal", "true")
        setIsClosed(isClosed === style.openModal ? style.closedModal : style.openModal)
    }

    const submitData = async (e) => {
        e.preventDefault();

        setButtonState('loading')

        const data = {
            firstName: e.target.name.value,
            email: e.target.email.value,
        }

        const response = await fetch('/.netlify/functions/add-contact', {
            method: 'POST',
            body: JSON.stringify(data)
        })

        const result = await response.json()

        if (result === "field_missing") {
            setButtonState('error')
            setResponseMessage("Fill in all the fields")
        } else if (result === "email_invalid") {
            setButtonState('error')
            setResponseMessage("Email is invalid")
        } else if (result === "duplicate") {
            setButtonState('error')
            setResponseMessage("Already registered")
        } else if (result === "Created") {
            trackEvent(null, null, null, "newsletter subscription")
            setButtonState('done')
            setResponseMessage("Thanks!")
            setTimeout(() => {
                setIsClosed(style.closedModal)
                setCookie("closedNewsletterModal", "true")
            }, 2000)
        } else {
            setButtonState('error')
            setResponseMessage("Unknown error")
        }
    }
    
    let modalStyle = style.container + ' ' + isClosed
    
    return (
        <div className={modalStyle}>
            <button className={style.closeBtn} onClick={toggleModal}>
                <div className={style.hamburgerLineContainer}>
                    <div className={style.hamburgerLine}></div>
                    <div className={style.hamburgerLine}></div>
                </div>
            </button>
            <form method="POST" onSubmit={submitData}>
                <h3>Get updates</h3>
                <p>Stay up to date with the latest SA Zeiner news and releases</p>
                <div className={style.inputGroup}>
                    <label htmlFor="name"><b>Name</b></label>
                    <input placeholder="Your name" name="name" type="text" />
                </div>
                <div className={style.inputGroup}>
                    <label htmlFor="email"><b>Email</b></label>
                    <input placeholder="Your email" name="email" type="text" />
                </div>
                <div className={style.inputGroup}>
                <SubmitButton buttonState={buttonState} responseMessage={responseMessage}/>
                </div>
            </form>
        </div >
    )
}

export default NewsletterForm