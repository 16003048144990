export default (type, label, category, action) => {
  if (typeof window !== "undefined") {
    if (window.gtag !== undefined) {
      window.gtag("event", type, {
        event_label: label,
        event_category: category,
        event_action: action
      })
    }
    if (window.fbq !== undefined) {
      window.fbq('trackCustom', action);
    }
  }
}